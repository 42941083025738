@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: #1f2944;
}

.footer {
  background-color: #191148;
  padding: 3rem 1.5rem 2rem;
}

.footer .button.is-primary {
  background-color: rgb(0, 93, 255) !important;
  font-weight: 600;
}

.text-ani {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.container {
  position: relative;
  width: 100%;
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 6rem;
  padding-left: 6rem;
}

.is-divider {
  height: 1px;
  margin: 20px 0;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.copyright-text {
  font-size: 14px;
}

/* menu section*/
.hero-body {
  padding: 0 !important;
}

.navbar {
  border-bottom: 1px solid #fa5400;
}

.navbar.is-fixed-top {
  position: sticky !important;
}

a.navbar-item:hover,
a.navbar-item:active,
a.navbar-item:focus {
  background-color: transparent;
}

a.navbar-item {
  font-weight: 600;
}

.is-normal {
  width: 12rem;
}

.h-line {
  border-bottom: 1px solid #ccc;
}

section {
  padding: 5rem 0;
}

.mr-15 {
  margin-right: 15px;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

/* Define the animation */
@keyframes slide-text {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes slide-text2 {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes slide-text3 {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fade-out {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

/* Apply the animation to the text element */
.text-slider {
  /* animation: slide-text 3s linear; */
}
/* .item-1,
.item-2,
.item-3 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 1em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.item-1 {
  animation-name: anim-1;
}

.item-2 {
  animation-name: anim-2;
}

.item-3 {
  animation-name: anim-3;
}

@keyframes anim-1 {

  0%,
  8.3% {
    left: -100%;
    opacity: 0;
  }

  8.3%,
  25% {
    left: 25%;
    opacity: 1;
  }

  33.33%,
  100% {
    left: 50%;
    opacity: 0;
  }
}

@keyframes anim-2 {

  0%,
  33.33% {
    left: -100%;
    opacity: 0;
  }

  41.63%,
  58.29% {
    left: 25%;
    opacity: 1;
  }

  66.66%,
  100% {
    left: 50%;
    opacity: 0;
  }
}

@keyframes anim-3 {

  0%,
  66.66% {
    left: -100%;
    opacity: 0;
  }

  74.96%,
  91.62% {
    left: 25%;
    opacity: 1;
  }

  100% {
    left: 50%;
    opacity: 0;
  }
} */
.is-info{
  background-color: #0965e6 !important;
  color: #fff;
}
.text-color{
  color: #0965e6;
}
.text-orange {
  color: #fa5400;
}
h2{
  font-size: 3rem;
}

/* .about-button .button:hover {
  background: #FFD583;
  transition: .8s all ease;
} */
/* .about-button {
  position: relative;
  display: block;
}
.about-button:hover{
  position: absolute;
    display: block;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD583;
    z-index: 1;
    overflow: hidden;
    transition: .8s all ease;
} */
/* Accordion */
.accordion {
  border: 0 !important;
}
.accordion__panel {
  margin-bottom: 10px;
}
/*forms */
.hero {
  padding: 1.5rem 0;
  background: url('../assets/bg.svg') repeat top center;
}

.text-size-12 {
  font-size: 12px;
  color: red;
}

::placeholder {
  font-size: 12px;
  color: #000;
}

.loanforms {
  background-color: rgba(3, 20, 91, 80%);
  padding: 20px;
}

.loanforms.forms-section {
  height: 564px !important;
  position: relative;
}

.centered-content {
  height: 564px;
  justify-content: center;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.form {
  position: relative;
  height: 40px;
  overflow: hidden;
  z-index: 1;
}

.form .input {
  width: 100%;
  height: 100%;
  color: #fff;
  /* padding-top: 10px; */
  border: none;
  background-color: transparent;
  padding-left: 10px;
}

.form label {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid #fff;
}

.form label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #fce38a;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.input,
.textarea {
  box-shadow: none;
}

.content-name {
  position: absolute;
  top: 5px;
  left: 0px;
  padding-bottom: 5px;
  transition: all 0.3s ease;
}

.form input:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

.form input:focus+.label-name .content-name,
.form input:valid+.label-name .content-name {
  transform: translateY(-35%);
  font-size: 14px;
  left: 0px;
  color: #fce38a;
}

.form input:focus+.label-name::after,
.form input:valid+.label-name::after {
  transform: translateX(0%);
}

.button.is-primary,
.offer-button {
  transition: background-color .3s ease-in-out,
  color .3s ease-in-out;
}

.offer-button {
  width: 100%;
  background-color: #f6d130 !important;
  font-weight: 600;
}

.offer-button:hover {
  background-color: #fff !important;
}

.button.is-primary:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.accordion__panel {
  animation: fadein 0.35s ease-in !important;
  background-color: #fff;
}

.hero-body {
  height: 600px;
}
.hero-body .is-size-1 {
  font-size: 32px;
  font-weight: bold;
}
.about-us {
  background: url('../assets/aboutus.jpg');
  background-color: #fff;
  height: 40em;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 2;
  position: relative;
}

.page-height {
  padding: 5em 0;
  height: 40em;
}

.about-button {
  background: none;
  border: 3px solid #0965e6;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: bold;
  width: 12rem;
  padding: 10px 20px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
}
.about-button::after {
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
  -o-transition: all 0.3s;
	transition: all 0.3s;
}

.about-button::before,
.about-button::after {
  background: #0965e6;
  content: '';
  position: absolute;
  z-index: -1;
}

.about-button:hover {
  color: #fff;
}
.btn-2::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}

.btn-2:hover:after {
  width: 100%;
}
/* logos section */

/* .bank-logo {
  display: inline-block;
  white-space: nowrap;
  animation: floatText 15s infinite linear;
  padding-left: 100%;
}
.bank-logo:hover {
  animation-play-state: paused;
}
@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}

.bank-img {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #000;
  margin: 5px 10px;
} */

/* loan section start here */

.type-loans {
  position: relative;
  z-index: 1;
}
/* Define the animation for the first box */
@keyframes box1-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.button {
  border-radius: 0;
}
/* Define the animation for the second box */
/* Define the animation */
/* @keyframes top-to-bottom {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

/* Apply the animation to the box element */
/* .box1 {
  animation: top-to-bottom 1s ease-in-out;
  animation-fill-mode: forwards;
}
.box2 {
  animation: top-to-bottom 2s ease-in-out;
  animation-fill-mode: forwards;
}
.box3 {
  animation: top-to-bottom 3s ease-in-out;
  animation-fill-mode: forwards;
} */
.type-loan,
.loan-detail {
  background-color: #191148;
  position: relative;
  padding: 2rem 3rem 2rem 2rem;
}

.vertical-text {
  position: absolute;
  left: 1.5rem;
  top: 2rem;
  right: 1.5rem;
  bottom: 1.5rem;
}

.vertical-line {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 1px;
  height: 100%;
}

.vertical-line-color {
  background-color: #494469;
}

.rotate {
  background-color: transparent;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  font-size: 0.875rem;
  font-weight: 400;
}

.text-rotate {
  position: absolute;
  top: 0;
  right: 0.7rem;
  background-color: #191148;
  padding-left: 10px;
  padding-right: 10px;
}

.loan-icon {
  background-color: #494469;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loan-text {
  position: relative;
  font-size: 1.25rem;
  line-height: 1.5;
}

.testimonial-container .loan-text {
  min-height: 360px;
  max-height: 100%;
}

.loan-text h3 {
  font-size: 1.5rem;
  /* color: #005dff; */
  color: #fff;
}
.img-style {
  width: 48px;
  height: 48px;
  color: #fff;
}

/* why-choose section start here*/

.why-choose {
  background-color: #191148;
}

.why-choose ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.why-choose ul li {
  list-style-type: disc;
  padding-left: 0;
  font-size: 16px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: #fff; 
}

.orange-color {
  color: #fa5400;
}

.bg-color {
  background-color: #005dff;
}
.why-left-col {
  background: url(../assets/whychooseus.jpg) no-repeat bottom center;
  background-size: cover;
  height: 672px;
  width: 720px;
}

.why-choose ul li .is-size-5 {
  font-size: 16px !important;
  font-weight: 700 !important;
}
/* */

.heading-line {
  position: relative;
  z-index: 1;
}

.heading-line::before {
  border-top: 1px solid #191148;
  position: absolute;
  content: '';
  margin: 0 auto;
  top: 60%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  z-index: -1;
}

.heading-text {
  background-color: #fff;
  color: #191148;
}

.mission-img {
  max-width: 70%;
}

/* how it works */

/* Define the animation */
@keyframes bottom-to-top {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Apply the animation to the box element */
/* .service-box1 {
  animation: bottom-to-top 1s ease-in-out;
  animation-fill-mode: forwards;
}
.service-box2 {
  animation: bottom-to-top 2s ease-in-out;
  animation-fill-mode: forwards;
}
.service-box3 {
  animation: bottom-to-top 3s ease-in-out;
  animation-fill-mode: forwards;
}
.service-box4 {
  animation: bottom-to-top 4s ease-in-out;
  animation-fill-mode: forwards;
} */
.how-it-works {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: 8rem;
  height: 32em;
}

.content-wrapper {
  position: relative;
  width: 56%;
  margin-left: auto;
}

.left-image {
  position: absolute;
  top: -8rem;
  right: 100%;
  bottom: -8rem;
  width: 50vw;
}
.text-content {
  position: relative;
  padding-left: 3rem;
  color: #fff;
}

.how-it-works .heading-line::before {
  border-color: #ccc;
}

.how-it-works  .heading-text {
  background-color: #0965e6 !important;
  color: #fff;
}

.right-col {
  display: grid;
  margin-top: 16rem;
  margin-bottom: 2rem;
  /* margin-left: -7.6rem; */
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.service-cards {
  background-color: #fff;
  padding: 1.5rem 1.5rem 3rem;
}

.service-cards .loan-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 2rem;
}
.card-text {
  position: relative;
  font-size: 1.25rem;
  line-height: 1.3;
  color: #191148;
}
.loans .text-rotate {
  border: 1px solid #494469;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  right: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.loans .rotate {
  transform: none;
}
.loans .columns > .column:last-child {
  padding-top: 32px;
}
.loans .mt-10 {
  padding-top: 10rem;
}
.mb-5 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 8rem;
}
/* */

.box-gap{
  margin-top: 1.5rem;
}
.scale {
  background: url(../assets/scale.png) no-repeat bottom center;
  background-size: cover;
  height: 315px;
  position: relative;
  padding-left: 1.7rem;
}

.range-line {
  width: 2px;
  height: 70%;
  position: absolute;
  top: 7rem;
  z-index: 2;
  background: rgba(9,101,230,1);
  background: -moz-linear-gradient(top, rgba(9,101,230,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(9,101,230,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(9,101,230,1) 0%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(9,101,230,1) 0%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(9,101,230,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(9,101,230,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0965e6', endColorstr='#ffffff', GradientType=0 );
}
.range-circle {
  position: absolute;
  content: '';
  width: 1rem;
  height:  1rem;
  background-color: #fff;
  border: 4px solid #0965e6;
  z-index: 3;
  top: 8.7rem;
  border-radius: 50%;
  left: 1.3rem;
}
/* .range-two {
  position: relative;
} */
.range-two .range-line {
  left: 49.3%;
  height: 100%;
  top: 2rem;
}
.range-two .range-circle {
  left: 48.3%;
  top: 4.4rem;
  z-index: 3;
}
.range-three .range-line {
  right: 5.5rem;
  height: 100%;
  top: -2rem;
}
.range-three .range-circle {
 left: 87%;
  top: -0.1rem;
  z-index: 3;
}
.text-col {
  position: absolute;
  left: 20px;
  top: -20px;
  width: 8rem;

}
/* testimonial section start*/

.testimonial .text-rotate {
  border: 1px solid #edf2f7;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  right: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
}
.testimonial .rotate {
  transform: none;
}
.testimonial .type-loan {
  background-color: #fff;
  color: #191148;
  box-shadow: 0 0px 1px #000;
}
.testimonial .text-rotate {
  background-color: #fff;
}

.testimonial  .vertical-line-color {
  background-color: #edf2f7;
}

.testimonial  .loan-text {
  color: #191148;
}

/* Define the animation */
@keyframes slide-fade-out-in {
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
  50% {
    transform: translateX(0);
    opacity: 0.5;
  }
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slide-fade-out-in2 {
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
  50% {
    transform: translateX(0);
    opacity: 0.5;
  }
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
/* Apply the animation to the image element */
.image-animation {
  animation: slide-fade-out-in 5s ease-in;
}
.image-animation2 {
  animation: slide-fade-out-in 15s ease-in;
}

.text-slide:hover {
   transform: translateX(5%);
}
.text-slide {
  transition: transform 1s;
  cursor: pointer;
}
.author {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #000;
  margin-right: 10px;
}
.author img {
  width: 100%;
  height: 100%;
  background-size: cover;
}
/* EMI calculator */

.card {
  background-color: #eee;
}
.card-header {
  background-color: #191148 !important;
  color: #fff;
}
.card-header-title {
  color: #fff !important;
}
.emi-total-view {
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #191148;
  border: 1px solid #eee
}
.total-count .card {
  background-color: transparent !important;
  color: #191148;
  box-shadow: none;
}
.total-count .card-header {
  background-color: transparent !important;
  box-shadow: none;
  height: 48px;
}
.control .icon {
  background-color: #dbdbdb;
  
}
.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #1f2944;
}
/* loan provider */
 
.btn-top {
  position: fixed;
  right: 20px;
  bottom: 5vh;
  width: 52px;
  height: 52px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fa5400;
  background-color: #191148;
  color: #fa5400;
  transition: all .3s ease-in-out;
  z-index: 99;
}

.btn-top:hover {
  background-color: #fa5400;
  color: #fff;
}

.about-mission h1,
.about-banner h1,
.about-values h1,
.contact-banner h1,
.contact-info-title {
  font-weight: 500;
  line-height: 90px;
  font-size: 58px;
}

.about-banner h1,
.contact-banner h1 {
  color: '#001943';
  font-size: 74px;
}


.about-stats {
  padding: 118px 0;
  margin: 0 64px;
}

.about-stats .block1-count,
.about-stats .block2-count,
.about-stats .block3-count {
  color: #001943;
  font-size: 120px;
  font-weight: 500;
  line-height: 125px;
}

.about-stats .columns .column:nth-child(2) {
  border: 1px solid #5a6a83;
  border-top-color: transparent;
  border-bottom-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-stats .columns .column:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.count-measure {
  font-size: 54px;
  display: flex;
  align-items: flex-end;
  line-height: 64px;
  color: #005dff;
  font-weight: 800;
}

.is-grey-text {
  color: #5a6a83
}

.about-mission {
  background-color: #001943;
  padding-top: 150px;
  padding-bottom: 0;
}

.join-btn {
  border: 1px solid white;
  background-color: #001943;
}

.join-btn:hover {
  background-color: white;
  color: #001943 !important;
}

.about-values {
  padding-top: 50px;
  background-color: #001943;
  border-bottom: 1px solid #fff;
}

.about-values h1 {
  line-height: 75px;
}

.value-box {
  height: 362px;
  width: 385px;
  border: 1px solid white;
  margin-bottom: 24px;
  padding: 30px 30px 103px;
}

.box-column1 {
  margin-right: 24px;
}

.box-column1 .value-box:first-child,
.box-column2 .value-box:last-child {
  background-color: white;
}

.box-column2 {
  margin-top: 85px;
}

.textarea-field .textarea {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 0;
  transition: border-color .3s ease-in-out;
}

.textarea-field .textarea::placeholder,
.textarea-field .textarea,
.contact-form .input::placeholder {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.textarea-field .textarea:focus,
.textarea-field .textarea:hover {
  border: 1px solid #ffc001;
}

.contact-banner {
  background-color: #001943;
}

.contact-info-title {
  line-height: 70px;
}

.contact-info-title,
.contact-info-text {
  width: 40%;
  text-align: center;
}

.box1,
.box2,
.box3 {
  width: auto !important;
  border: 0;
  padding: 50px 24px;
  height: auto !important;
}

.contact-info-box .box1,
.contact-info-box .box2,
.contact-info-box .box3 {
  height: 425px !important;
}

.box1{
  background-color: #005dff;
}

.box2 {
  background-color: #003083;
}

.box3 {
  background-color: #001943;
}

.contact-info-box {
  margin: 0 24px;
}

.contact-form .input{
  background-color: transparent;
  border-radius: 0;
  padding: 25px 24px 23px;
  color: white;
  transition: border-color .3s ease-in-out;
}

.contact-form .input:focus,
.contact-form .input:hover {
  border-color: #005dff;
  color: #fff;
  box-shadow: none;
}

.control.has-icons-left .input {
  padding-right: 0.5em;
  padding-left: 3em !important;
}

.is-hidden {
  display: none !important;
}
.terms-conditions p {
  margin-left: 16px;
}
.terms-conditions h2 {
  font-weight: bold;
}
.text-slider1 {
  animation: slide-text 1s ease-in;
}

.text-slider2 {
  animation: slide-text2 1s ease-in;
}

.text-slider3 {
  animation: slide-text3 1s ease-in;
}

.fade-out {
  animation: fade-out 1s ease-out;
}

.loading-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* pointer-events: none; */
  z-index: 99;
}

/* img {
  filter: gray;  
  -webkit-filter: grayscale(1); 
  filter: grayscale(1);  
}*/

/* Disable grayscale on hover */
/* img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
} */

.mob-number a{
  color: #000;
  font-weight: 600;
}
.about-banner .button.is-primary.is-normal {
  background-color: rgb(0, 93, 255) !important;
  transition: border-color .3s ease-in-out,
  background-color .3s ease-in-out,
  color .3s ease-in-out;
}
.about-banner .button.is-primary.is-normal:hover {
  background-color: #fff !important;
  border: 1px solid rgb(0, 93, 255) !important;
}

.social-icons a {
  color: #fff;
  transition: color .3s ease-in-out;
}

.social-icons a:hover {
  color: #005dff;
}

.contact-form .offer-button {
  background-color: #005dff !important;
  color: white;
  transition: background-color .3s ease-in-out,
  color .3s ease-in-out;
}

.contact-form .offer-button:hover {
  background-color: #f6d130 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}


@media only screen and (max-width: 767px)  {
  .container {
    padding: 0  1rem !important;
  }
  .loanforms.forms-section, .hero-body {
    height: 100% !important;
  }
  section{
    padding: 1rem 0;
  }
  .mb-4 {
    margin-bottom: 15px;
  }
  .page-height {
    padding: 1rem 0;
  }
  .why-left-col {
    height: 580px;
  }
  .how-it-works {
    height: auto;
  }
  .left-image {
    width: 100vw;
    right: -6%;
  }
  .text-content {
    top: 15rem;
    padding-left: 0;
  }
  .right-col {
    margin-left: 0;
    margin-top: 10rem;
  }
  .service-cards {
    width: 75vw;
  }
  .content-wrapper {
    width: 100%;
  }
  .box-gap {
    margin-top: 0;
  }
  .testimonial-container .loan-text {
    min-height: auto;
    margin-bottom: 16px;
  }
  .about-stats .columns .column:nth-child(2) {
    border: 0;
  }
  .about-stats .columns .column:nth-child(3) {
    align-items: center;
  }
  .about-values .is-flex {
    display: revert !important;
  }
  .contact-info-title, .contact-info-text {
    width: 90%;
  }
  .about-mission h1 {
    line-height: 50px;
    font-size: 38px;
  }
 }

 @media only screen and (min-width: 768px) and (max-width: 1023px) {
  .loanforms.forms-section, .hero-body {
    height: 100% !important;
  }
  .why-left-col {
    height: 580px;
    width: 100%;
    background-position: top center;
    background-size: cover;
  }
  .how-it-works {
    height: auto;
  }
  .left-image {
    position: static;
  }
  .content-wrapper {
    width: auto;
  }
  .left-image {
    width: 100%;
  }
  .how-it-works {
    padding-top: 24px;
    padding-bottom: 0px;
  }
  .service-cards {
    width: 75vw;
  }
  .right-col {
    margin-top: 10rem;
  }
  .about-stats .columns .column:nth-child(2) {
    border: 0 !important;

  }
  .about-stats .columns .column .is-flex {
    justify-content: center;
  }
  .about-stats .columns .column .is-flex + div {
    text-align: center;
  }
  .about-stats .columns .column:nth-child(3) {
    align-items: center;
  }
  .about-values .is-flex {
    display: revert !important;
  }
  .box-column2 {
    margin-top: 0;
  }
  .about-mission .is-pulled-right{
    float: unset !important;
  }
  .vision-columns {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .footer .columns {
    display: flex;
    flex-wrap: wrap;
  }
  .footer .columns .column {
    flex-basis: 50%;
  }
  .column.mt-10 .box-gap{
    margin-top: 0;
    padding-top: .75em !important;
  }
 }

 @media only screen and (min-width: 1024px) and (max-width: 1335px) {
  .hero-body.home .columns{
    flex-direction: column;
  }
  .loanforms.forms-section, .hero-body {
    height: 100% !important;
  }
  .type-loans .loan-text {
    height: 90px;
  }
  .why-left-col {
    height: 100%;
    width: 100%;
  }
  .right-col {
    margin-top: 14rem;
    margin-left: -12rem;
  }
  .how-it-works .loan-text {
    height: 140px;

  }
  .testimonial {
    padding-top: 0 !important;
  }
  .box-column2 {
    margin-top: 0;
  }
 }
